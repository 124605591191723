// 1. Your custom variables and variable overwrites.
@import url('https://fonts.googleapis.com/css2?family=Germania+One&display=swap');
$global-background: #222;
$global-color: #eeeeee;
$global-link-color: #fbf;
$global-emphasis-color: #fefefecc;
$global-primary-background: #111;
$global-secondary-background: #333;
$global-success-background: #2f2;
$global-danger-background: #f22;
$global-muted-background: #444;
$global-font-family: 'Noto Serif', serif;
$global-font-size: 16px;
$base-heading-font-family:  'Germania One', serif;
$navbar-background: #111;
$nav-default-item-color:  #fefefe;
$base-link-hover-text-decoration: none;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";


// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";